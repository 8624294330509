import React from 'react'
import Layout from '../components/Layout'
import error from "../img/404.svg";

const NotFoundPage = () => (
  <Layout>
    <div className="error-container">
        <div className="error-content">
            <img src={error} alt="icon" style={{ width: '100px', height: '100px'}} />
            <h1>Błąd 404</h1>
            <p>Coś się zbugowało...</p>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
